<template>
  <div>
    <div v-if="election">
      <picture>
        <source
          :srcset="`/vaa-graphics/${type}/bottom-mobile.webp`"
          type="image/webp"
        />
        <source
          :srcset="`/vaa-graphics/${type}/bottom-mobile.png`"
          type="image/png"
        />
        <img
          class="block md:hidden h-full w-full object-cover"
          :src="`/vaa-graphics/${type}/bottom-mobile.png`"
          alt=""
        />
      </picture>
      <picture>
        <source
          :srcset="`/vaa-graphics/${type}/bottom.webp`"
          type="image/webp"
        />
        <source :srcset="`/vaa-graphics/${type}/bottom.png`" type="image/png" />
        <img
          class="hidden md:block h-full w-full object-cover"
          :src="`/vaa-graphics/${type}/bottom.png`"
          alt=""
        />
      </picture>
    </div>
    <hr
      v-else
      class="border-0 bg-black w-full h-px"
      :class="[
        customMargin ? customMargin : isMm ? 'my-3' : 'my-4',
        dark ? 'bg-opacity-50' : 'bg-opacity-10',
      ]"
    />
  </div>
</template>

<script setup lang="ts">
const { isMm } = useBrand()
defineProps({
  dark: {
    type: Boolean,
    default: false,
  },
  election: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'KV21',
  },
  customMargin: {
    type: String,
    default: undefined,
  },
})
</script>
